<script setup>
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import PoiIcon from '@/Shared/Components/PoiIcon.vue'

const props = defineProps({
  poi: Object,
})

const params = {
  lat: props.poi.coordinates.coordinates[1],
  lng: props.poi.coordinates.coordinates[0],
  label: props.poi.name,
  icon: props.poi.icon,
}

const newParams = new URLSearchParams(params).toString()
</script>

<template>
  <div class="flex items-center mt-4 mb-4 justify-between">
    <div class="flex items-center">
      <div
        class="w-5 h-5 sm:w-10 sm:h-10 flex-shrink-0 rounded-full flex justify-center items-center self-start bg-opacity-30"
        :class="props.poi.background_color"
      >
        <PoiIcon :poi-type="props.poi.icon" />
      </div>

      <span class="ml-5">
        {{ props.poi.name }}
      </span>
    </div>
    <div>
      <LinkButton
        type="a"
        class=""
        :href="`/about-bus-services/explore-bus-options/location/?${newParams}`"
      >
        view
      </LinkButton>
    </div>
  </div>
</template>
