import { ref, onMounted, onUnmounted } from 'vue'

export function useBreakpoint() {
  const currentBreakpoint = ref('')

  const handleResize = () => {
    const width = window.innerWidth

    if (width < 576) {
      currentBreakpoint.value = 'xs'
    } else if (width < 768) {
      currentBreakpoint.value = 'sm'
    } else if (width < 992) {
      currentBreakpoint.value = 'md'
    } else if (width < 1200) {
      currentBreakpoint.value = 'lg'
    } else {
      currentBreakpoint.value = 'xl'
    }
  }

  onMounted(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
  })

  return {
    currentBreakpoint,
  }
}
