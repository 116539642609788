<script setup>
import { computed, ref } from 'vue'
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import { getFormattedTimeDifference } from '@/Shared/Helpers/dateHelper.js'
import BusRoutePois from './BusRoutePois.vue'

const props = defineProps({
  busRoute: Object,
})

const allArrivals = ref(props.busRoute.next_departures_for_stop)

const nextArrival = computed(() => {
  if (allArrivals.value.length > 0) {
    return allArrivals.value[0]
  }

  return 'N/A'
})

const nextArrivalFromNow = computed(() => {
  if (nextArrival.value != 'N/A') {
    const formattedTime = getFormattedTimeDifference(nextArrival.value)

    return formattedTime.length > 0 ? formattedTime : 'Due now'
  }

  return 'N/A'
})

const allOtherArrivals = computed(() => {
  if (allArrivals.value.length > 1) {
    return `${allArrivals.value.slice(1).join(', ')}`
  }

  return 'N/A'
})

const timetableLink = computed(() => {
  return route('timetable.details.show', { route: props.busRoute.route_id })
})

const busRouteLink = computed(() => {
  const params = new URLSearchParams(window.location.search)

  return `/about-bus-services/explore-bus-options/route/${props.busRoute.route_id}?${params}`
})
</script>

<template>
  <div class="bg-white rounded-xl border border-[#EEEEEE] divide-y divide-[#DEDEDE] shadow-[0_0_10px_rgba(0,0,0,0.1)]">
    <div class="px-4 pt-4">
      <div class="flex items-center">
        <span class="bg-[#1D594C] text-white py-1 px-6 rounded-lg mr-2">{{ busRoute.route_short_name }}</span>
        <h4 class="font-semibold text-[#1D594C] text-lg truncate">to {{ busRoute.next_trip_headsign }}</h4>
      </div>
      <dl class="grid grid-cols-2">
        <div class="p-4 sm:col-span-1 sm:px-0">
          <dt class="text-sm">Arriving at</dt>
          <dd class="mt-1 text-xl font-bold text-[#1D594C] sm:mt-2">{{ nextArrival }}</dd>
          <span class="inline-block bg-[#ECF1F5] rounded-lg py-1 px-2 mt-2">{{ nextArrivalFromNow }}</span>
        </div>
        <div class="p-4 sm:col-span-1 sm:px-0">
          <dt class="text-sm">Then in</dt>
          <dd class="mt-1 font-bold sm:mt-2">{{ allOtherArrivals }}</dd>
          <LinkButton
            type="a"
            class="inline-block font-medium mt-2"
            :href="timetableLink"
          >
            View timetable
          </LinkButton>
        </div>
      </dl>
    </div>
    <div class="px-4 pb-4">
      <dl class="grid grid-cols-2">
        <div class="p-4 sm:col-span-1 sm:px-0">
          <dt class="text-sm">First bus</dt>
          <dd class="mt-1 font-bold text-[#1D594C] sm:mt-2">
            {{ busRoute.first_departure_for_stop }}
          </dd>
        </div>
        <div class="p-4 sm:col-span-1 sm:px-0">
          <dt class="text-sm">Last bus</dt>
          <dd class="mt-1 font-bold text-[#1D594C] sm:mt-2">
            {{ busRoute.last_departure_for_stop }}
          </dd>
        </div>
      </dl>

      <BusRoutePois
        :bus-route="busRoute"
        class="min-h-[220px]"
      />
      <PrimaryButton
        as="a"
        class="w-full py-3 justify-center"
        :href="busRouteLink"
      >
        View bus route
      </PrimaryButton>

      <LinkButton class="font-medium my-4">Find out about fare prices</LinkButton>
    </div>
  </div>
</template>
