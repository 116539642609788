<script setup>
import { ref, onMounted } from 'vue'
import BusStopIcon from '@svgs/bus-stop-icon.svg'
// import BusLoadingIcon from '@svgs/bus-loading-icon.svg'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import ImportantInformation from '@/Shared/Components/Sections/ImportantInformation.vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import AutoComplete from '@/Public/Partials/ExploreBusOption/AutoComplete.vue'

const props = defineProps({
  isHomepage: Boolean,
  canSearchOnMount: Boolean,
})

const form = ref({ processing: false, errors: {} })

const params = new URLSearchParams(document.location.search)

const autocomplete = ref(null)

if (params.has('type') && params.has('id')) {
  const values = {
    type: params.get('type'),
    id: params.get('id'),
    label: params.get('label'),
  }

  if (params.get('type') == 'place') {
    values.lat = params.get('lat')
    values.lng = params.get('lng')
  }

  if (params.get('type') == 'poi') {
    values.icon = params.get('icon')
  }

  autocomplete.value = values
}

onMounted(() => {
  if (autocomplete.value && props.canSearchOnMount.value) {
    onSearch()
  }
})

const onSearch = () => {
  const params = new URLSearchParams(autocomplete.value).toString()

  if (autocomplete.value.type == 'route') {
    window.location.href = `/about-bus-services/explore-bus-options/route/${autocomplete.value.id}?${params}`
  } else {
    window.location.href = `/about-bus-services/explore-bus-options/location/?${params}`
  }
}
</script>

<template>
  <div class="flex flex-col p-4 bg-white shadow-[0_0px_35px_rgba(0,0,0,0.1)] rounded-tr-lg rounded-b-lg">
    <div class="flex max-lg:flex-col outline-none max-lg:rounded-tl-lg">
      <div
        v-if="!isHomepage"
        class="flex items-center flex-none mr-6"
      >
        <BusStopIcon
          class="w-12 h-12 mr-3"
          aria-hidden="true"
        />
        <h3 class="font-semibold text-[22px] py-4">Discover buses</h3>
      </div>
      <div class="flex items-center grow max-lg:mb-4 lg:mr-4">
        <div class="relative w-full">
          <AutoComplete v-model="autocomplete" />
        </div>
      </div>
      <div class="flex items-center flex-none">
        <PrimaryButton
          class="w-full px-12 justify-center"
          :class="{ 'opacity-25': form.processing }"
          :disabled="!autocomplete || form.processing"
          @click="onSearch"
        >
          Show me
        </PrimaryButton>
      </div>
    </div>
    <ImportantInformation class="mt-4 w-full">
      <p>
        Please note, the bus service information provided about your stop is sensitive to the time of your enquiry. So,
        if you are enquiring about another day or vastly different time of day we would recommend using the
        <AppLink
          :href="'/about-bus-services/plan-a-journey'"
          intertia="true"
        >
          Plan a Journey
        </AppLink>
        functionality.
      </p>
    </ImportantInformation>
  </div>
  <!--<div class="flex flex-col items-center mt-14">
    <BusLoadingIcon aria-hidden="true" />
    <h2 class="text-black font-extrabold text-3xl pt-8">Your bus options</h2>
    <h3 class="font-semibold text-[22px] py-2">We're finding your bus options</h3>
  </div>-->
</template>
