<script setup>
import PublicTable from '@/Shared/Components/Tables/PublicTable.vue'
import ZeroData from '@/App/Components/ZeroData.vue'
import PublicPagination from '@/Public/Components/PublicPagination.vue'

const props = defineProps({
  routes: Object,
})

const columns = [
  { name: 'Service', slug: 'service' },
  { name: 'Route', slug: 'route' },
  { name: 'Provider', slug: 'provider' },
  { name: 'Last updated' },
]
</script>

<template>
  <div>
    <PublicTable
      :columns="columns"
      :rows="props.routes.data"
    >
      <template #zeroData>
        <ZeroData
          :search="props.search"
          table-name="timetables"
        />
      </template>
      <template #default="{ row }">
        <td class="py-4 font-bold">{{ row.route_short_name }}</td>
        <td>{{ row.route_long_name }}</td>
        <td class="text-sm">{{ row.agency_name }}</td>
        <td class="text-sm">{{ row.last_updated }}</td>
        <td class="text-right">
          <a
            class="text-blue underline decoration-1 hover:decoration-2 focus:outline-none focus:bg-yellow-500"
            :href="route('timetable.details.show', { route: row.route_id })"
          >
            View timetable
          </a>
        </td>
      </template>
    </PublicTable>
  </div>

  <div class="mt-8">
    <PublicPagination
      :links="props.routes.links"
      :meta="props.routes.meta"
    />
  </div>
</template>
