<script setup>
import axios from 'axios'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import debounce from 'lodash/debounce'
import { ref, computed, watch, onMounted } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import InputLabel from '@/Shared/Components/Forms/InputLabel.vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
  ComboboxButton,
} from '@headlessui/vue'
// import { XCircleIcon } from '@heroicons/vue/20/solid'
import { useModelWrapper } from '@/Shared/Composables/useModelWrapper.js'

const props = defineProps({
  modelValue: Object,
  query: String,
})

const loading = ref(false)
const noResults = ref(false)
const search = ref(null)
const isInitialSearch = ref(true)
const emits = defineEmits(['update:modelValue'])
const selectedOption = useModelWrapper(props, emits)

const placeOptions = ref([])
const gtfsOptions = ref([])
const autocompleteButton = ref(null)

const options = computed(() => {
  return [...placeOptions.value, ...gtfsOptions.value].sort((a, b) => a.sort.localeCompare(b.sort))
})

const intelliOptions = computed(() => {
  const boldSearchInput = `<b>${search.value}</b>`

  return options.value.map((option) => {
    const label = option.label.toLowerCase()
    const index = label.indexOf(search.value.toLowerCase())

    if (index > -1) {
      const boldLabel = `${label.slice(0, index)}${boldSearchInput}${label.slice(index + search.value.length)}`

      return { ...option, intelliLabel: boldLabel }
    }
    return { ...option, intelliLabel: option.label }
  })
})

watch(
  search,
  debounce(() => {
    if (search.value.length === 0) {
      noResults.value = false
      gtfsOptions.value = []
      return
    }
    getOptions()
    loading.value = true
  }, 500)
)

const getOptions = () => {
  Promise.all([gtfsApi.autocompleteTimetables(search.value)])
    .then(
      axios.spread((gtfsResponse) => {
        if (gtfsResponse.status === 200) {
          gtfsOptions.value = gtfsResponse.data.map((route) => {
            return {
              type: 'route',
              id: route.route_id,
              label: `${route.name} - ${route.long_name}, ${route.agency_name}`,
              sort: `${route.long_name}`,
            }
          })
        }

        noResults.value = gtfsOptions.value.length === 0

        if (props.query && isInitialSearch.value) {
          autocompleteButton.value.$el.click()
        }
        isInitialSearch.value = false
      })
    )
    .catch((e) => console.error(e))
    .finally(() => (loading.value = false))
}

const onReset = () => {
  selectedOption.value = null
  window.history.replaceState(null, null, window.location.href.split('?')[0])
}

onMounted(() => {
  if (props.query) {
    search.value = props.query
  }
})
</script>

<template>
  <div>
    <Combobox
      v-model="selectedOption"
      nullable
    >
      <div class="relative">
        <div
          class="relative w-full cursor-default rounded-lg bg-white text-left sm:text-sm"
          @click="onReset"
        >
          <InputLabel
            for="search"
            class="sr-only"
            value="Search by service number, location name"
          />
          <ComboboxInput
            autocomplete="off"
            class="w-full border-black rounded-md pl-10 shadow-sm focus:border-2 focus:ring focus:ring-yellow-500"
            :display-value="(option) => (option ? option.label : search ? search : '')"
            :disabled="selectedOption !== null"
            placeholder="Search by service number, location name"
            @change="search = $event.target.value"
          />
          <ComboboxButton
            ref="autocompleteButton"
            class="hidden"
          />
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <!-- <button
            v-if="selectedOption !== null"
            type="button"
            class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none focus:ring focus:ring-yellow-500"
            tabindex="0"
            @click="onReset"
          >
            <XCircleIcon
              class="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </button> -->
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="search = ''"
        >
          <ComboboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          >
            <div
              v-if="loading && options.length === 0"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              Loading...
            </div>
            <div
              v-else-if="noResults"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              No results found...
            </div>

            <ComboboxOption
              v-for="(option, index) in intelliOptions"
              :key="index"
              v-slot="{ active }"
              as="template"
              :value="option"
            >
              <li
                class="relative cursor-pointer select-none py-2 px-4"
                :class="{
                  'bg-yellow-500 text-black': active,
                  'text-black': !active,
                }"
              >
                <span
                  class="block truncate"
                  v-html="option.intelliLabel"
                ></span>
                <span class="block text-sm">
                  {{ option.type == 'route' ? 'Bus route' : 'Location' }}
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>
