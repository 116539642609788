<script setup>
import { ref, computed, watch } from 'vue'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import AppSpinner from '@/Shared/Components/AppSpinner.vue'
import { getDatesFromTodayOnwards } from '@/Shared/Helpers/dateHelper.js'
import SelectInput from '@/Shared/Components/Forms/SelectInput.vue'
import Switch from '@/Shared/Components/Forms/Switch.vue'
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import { jsPDF } from 'jspdf'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

/* Props */

const props = defineProps({
  busRouteId: [Number, String],
  titleClass: {
    type: String,
    default: 'text-lg font-bold',
  },
  downloadLink: {
    type: Boolean,
    default: false,
  },
  showAllStops: {
    type: Boolean,
    default: false,
  },
})

/* Data */

const currentRoute = ref(null)
const loading = ref(true)
const currentDate = ref('Today')
const currentDirection = ref(null)
const isShowAllStopsChecked = ref(props.showAllStops)

const dates = getDatesFromTodayOnwards()

/* Computed */

const currentTimetable = computed(() => {
  return currentDirection.value ? currentRoute.value.timetables[currentDirection.value.id] : null
})

const directions = computed(() => {
  return currentRoute.value
    ? currentRoute.value.timetables.map((timetable, index) => ({
        id: index,
        name: timetable.direction,
      }))
    : []
})

const stopsToShow = computed(() => {
  if (!currentTimetable.value) {
    return []
  }
  return isShowAllStopsChecked.value
    ? currentTimetable.value.stops
    : currentTimetable.value.stops.filter((stop) => stop.is_principal)
})

const stopCountDifference = computed(() => {
  return currentTimetable.value ? currentTimetable.value.stops.length - stopsToShow.value.length : 0
})

/* Methods */

const fetchTimetable = () => {
  loading.value = true

  gtfsApi
    .routeTimetable(props.busRouteId, currentDate.value)
    .then((response) => {
      currentRoute.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      loading.value = false
    })
}

const getTimeForStop = (times, stopId) => {
  const stopTime = times.find((time) => time.stop_id == stopId)
  return stopTime ? stopTime.departure_time : '-'
}

const downloadTimetable = () => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'a4',
    hotfixes: ['px_scaling'],
  })

  let timetable = document.getElementById('timeTable')

  doc.html(timetable, {
    html2canvas: {
      scale: timetable.innerHTML.length > 8500 ? 0.22 : 1,
    },
    callback: function (doc) {
      //doc.save('sample-document.pdf')
      window.open(doc.output('bloburl'))
    },
    margin: [20, 20, 20, 20],
    autoPaging: 'text',
  })
}

/* Watch */

watch(currentDate, () => {
  fetchTimetable()
})

watch(directions, (newDirections) => {
  /* sets the current direction value to the first of available directions */
  currentDirection.value = newDirections.length ? newDirections[0] : null
})

/* Mounted */
fetchTimetable()
</script>

<template>
  <div class="flex flex-col lg:flex-row justify-between lg:items-center px-4 md:px-0">
    <h2 :class="props.titleClass">Timetable</h2>

    <div
      v-if="directions"
      class="flex flex-col py-3 items-start lg:flex-row justify-end lg:items-center"
    >
      <div class="contents py-3 md:py-0 md:flex items-center">
        <div class="flex items-center py-3 md:py-0">
          <span
            v-if="isShowAllStopsChecked"
            class="text-sm"
          >
            Showing all stops
          </span>
          <span
            v-else
            class="text-sm"
          >
            Show all stops (+{{ stopCountDifference }} more)
          </span>

          <Switch
            v-model:checked="isShowAllStopsChecked"
            class="mx-2"
          />
        </div>

        <div class="flex items-center py-3 md:py-0">
          <span class="text-sm">Direction:</span>

          <SelectInput
            v-model="currentDirection"
            class="ml-2 mr-4 min-w-[200px] w-5/6"
            name="directions"
            placeholder="Select direction"
            :items="directions"
            label-key="name"
            label-value="id"
          />
        </div>
      </div>

      <div class="flex items-center py-3 md:py-0">
        <span class="text-sm">Day:</span>

        <SelectInput
          v-model="currentDate"
          class="ml-2 min-w-[200px]"
          name="day"
          placeholder="Select day"
          :items="dates"
        />
      </div>
    </div>
  </div>

  <AppSpinner
    v-if="loading"
    class="w-8 h-8 mt-6 mx-auto"
  />

  <div
    v-else
    class="px-2 md:px-0"
  >
    <div
      v-if="currentTimetable"
      class="mt-2 border rounded-md"
    >
      <p class="p-3">
        <strong>{{ currentRoute.route_short_name }} - {{ currentTimetable.direction }}</strong>
        <span
          v-if="props.downloadLink"
          class="float-right"
        >
          <LinkButton @click="downloadTimetable()">Download this timetable</LinkButton>
        </span>
      </p>

      <table
        id="timeTable"
        class="table-auto overflow-x-auto w-full block px-2 md:px-0"
      >
        <thead>
          <th class="p-2 whitespace-nowrap bg-gray-100 sticky left-0 text-left text-sm font-medium">Stop name</th>

          <th
            v-for="(i, index) in currentTimetable.times"
            :key="index"
            class="bg-gray-100"
          ></th>
        </thead>

        <tbody>
          <tr
            v-for="(stop, index) in stopsToShow"
            :key="index"
          >
            <td
              class="border p-2 whitespace-nowrap sticky left-0 bg-white"
              :class="{ 'font-bold': stop.is_principal }"
            >
              <AppLink :href="route('stop-details.show', { stop: stop.stop_id })">
                {{ stop.stop_name }}
              </AppLink>
            </td>

            <td
              v-for="(times, timesIndex) in currentTimetable.times"
              :key="timesIndex"
              class="border p-2 text-center"
            >
              {{ getTimeForStop(times, stop.stop_id) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="p-3">Showing {{ stopsToShow.length }}/{{ currentTimetable.stops.length }} stops.</p>
    </div>
    <div v-else>No timetable for {{ currentDate }}</div>
  </div>
</template>
