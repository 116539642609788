<script setup>
import AddToFavourites from '@/Public/Components/AddToFavourites.vue'
import BusSearch from '@/Public/Partials/ExploreBusOption/BusSearch.vue'
import { useFavouritesStore } from '@/Public/Stores/FavouritesStore'
import BusStopCard from '@/Public/Partials/BusStop/BusStopCard.vue'
import ServiceCard from '@/Public/Partials/MyFavourites/ServiceCard.vue'

let newFavouritesStore = useFavouritesStore()
</script>

<template>
  <div
    v-if="
      newFavouritesStore.busStops.length == 0 &&
      newFavouritesStore.services.length == 0 &&
      newFavouritesStore.parkAndRides.length == 0
    "
  >
    <div class="grid grid-cols-1 lg:grid-cols-2 mt-6">
      <div>
        <h2 class="font-extrabold text-lg pb-5">You currently have no stops or services added to your favourites.</h2>
        <p>Look out for the button below to add items to your favourites page:</p>
        <AddToFavourites
          :disable-favourite-link="true"
          class="mt-10"
        />
        <p class="text-sm mb-12 mt-2">
          Selecting the “Add to favourites” button will add services and stops to this page
        </p>
        <p>Search below to start adding to your favourites</p>
      </div>
    </div>
    <BusSearch class="mt-6"></BusSearch>
  </div>

  <div v-if="newFavouritesStore.services.length > 0">
    <h2 class="font-extrabold text-xl mt-6 mb-4">Services</h2>

    <div class="mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">
      <ServiceCard
        v-for="service in newFavouritesStore.services"
        :key="service.route_id"
        :service="service"
      />
    </div>
  </div>

  <div v-if="newFavouritesStore.busStops.length > 0">
    <h2 class="font-extrabold text-xl mt-6 mb-4">Bus stops</h2>

    <div class="mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">
      <BusStopCard
        v-for="busStop in newFavouritesStore.busStops"
        :key="busStop.stop_id"
        :bus-stop="busStop"
      />
    </div>
  </div>

  <div v-if="newFavouritesStore.parkAndRides.length > 0">
    <h2 class="font-extrabold text-xl mt-6 mb-4">Park and Ride</h2>

    <div class="mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">
      <BusStopCard
        v-for="busStop in newFavouritesStore.parkAndRides"
        :key="busStop.stop_id"
        :bus-stop="busStop"
      />
    </div>
  </div>
</template>
