<script setup>
import { onMounted, ref, watch } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import { GoogleMap, CustomMarker, MarkerCluster, Polygon, InfoWindow } from 'vue3-google-map'
import { GridAlgorithm } from '@googlemaps/markerclusterer'
import debounce from 'lodash/debounce'
import BusLiveBearingMarkerIcon from '@svgs/bus-live-bearing-marker-icon.svg'
import BusLiveMarkerIcon from '@svgs/bus-live-marker-icon.svg'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import Switch from '@/Shared/Components/Forms/Switch.vue'
import ImportantInformation from '@/Shared/Components/Sections/ImportantInformation.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

defineProps({
  showDisclaimer: {
    type: Boolean,
    default: false,
  },
})
const clusterOptions = { algorithm: new GridAlgorithm({ gridSize: 50 }) }

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['geometry', 'places'],
})

const map = ref(null)
const markers = ref([])
const loading = ref(false)
const apiPromise = loader.load()
const drtServices = ref({})
const polygonData = ref([])
const showDrtServiceArea = ref(false)
const hasAnyServices = ref(false)
// apiPromise.then((google) => {})

const center = ref({ lat: 51.735991288633066, lng: 0.46658261948208646 })

const essexBounds = ref({
  north: 52.117316,
  south: 51.419518,
  west: -0.119,
  east: 1.972765,
})

const onMapMove = debounce(() => {
  const newCenter = map.value.map.getCenter()
  gtfsApi.liveUpdatesNearest(newCenter.lat(), newCenter.lng(), 10).then((response) => {
    loading.value = false
    if (response.status === 200) {
      markers.value = response.data
    }
  })
}, 500)

const onMarkerSelected = (marker) => {
  if (marker.gtfs_db_route_id) {
    window.open(`/about-bus-services/explore-bus-options/route/${marker.gtfs_db_route_id}`)
  }
  if (marker.gtfs_db_route_id == null) {
    window.open(route('timetable.cities.index', { route_name: marker.route_short_name }))
  }
}

onMounted(() => {
  loading.value = true
  gtfsApi.drtServices().then((response) => {
    if (response.status === 200) {
      drtServices.value = response.data
      if (Object.keys(drtServices.value).length !== 0) {
        hasAnyServices.value = true
      }
      loading.value = false
    }
  })
})

watch(showDrtServiceArea, () => {
  polygonData.value = []
  if (showDrtServiceArea.value && hasAnyServices.value) {
    drtServices.value.forEach((service) => {
      polygonData.value.push({
        paths: service.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        visible: true,
        clickable: true,
        name: service.name,
        showInfo: false,
        showInfoPosition: null,
        url: service.find_out_more_link,
      })
    })
  }
})

const showDrtInfo = (polygon, event) => {
  polygon.showInfoPosition = event.latLng
  polygon.showInfo = !polygon.showInfo
}
</script>

<template>
  <div
    v-if="hasAnyServices"
    class="mb-4 float-right flex items-center md:py-0"
  >
    <span
      v-if="showDrtServiceArea"
      class="text-sm"
    >
      Showing all DaRT areas
    </span>
    <span
      v-else
      class="text-sm"
    >
      Show DaRT area
    </span>

    <Switch
      v-model:checked="showDrtServiceArea"
      class="mx-2"
    />
  </div>
  <GoogleMap
    ref="map"
    :api-promise="apiPromise"
    :center="center"
    class="w-full h-[512px] my-4"
    :zoom="13"
    :restriction="{
      latLngBounds: essexBounds,
      strictBounds: true,
    }"
    :styles="[
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ]"
    @bounds_changed="onMapMove"
  >
    <MarkerCluster :options="clusterOptions">
      <CustomMarker
        v-for="marker in markers"
        :key="marker.vehicle_id"
        :options="{
          position: { lat: parseFloat(marker.position_latitude), lng: parseFloat(marker.position_longitude) },
        }"
      >
        <div
          class="relative cursor-pointer"
          @click="onMarkerSelected(marker)"
        >
          <BusLiveMarkerIcon />
          <div
            class="absolute top-1 left-16 -z-10 bg-[#1D594C] py-1 pl-6 pr-4 rounded-r-xl text-base font-bold text-white text-center"
          >
            {{ marker.route_short_name }}
          </div>
          <BusLiveBearingMarkerIcon
            class="absolute left-1/2 bus-live-marker-icon"
            :style="`--rotation: ${marker.position_bearing}deg;`"
          />
        </div>
      </CustomMarker>
    </MarkerCluster>
    <div
      v-for="polygon in polygonData"
      :key="polygon.name"
    >
      <Polygon
        v-if="showDrtServiceArea"
        :options="polygon"
        @click="showDrtInfo(polygon, $event)"
      />
      <InfoWindow
        v-if="polygon.showInfo"
        :options="{ position: polygon.showInfoPosition }"
      >
        <div>
          <h3 class="text-lg font-bold">{{ polygon.name }}</h3>
          <p class="text-sm">
            <AppLink
              :href="polygon.url"
              target="_blank"
            >
              More Information
            </AppLink>
          </p>
        </div>
      </InfoWindow>
    </div>
  </GoogleMap>
  <ImportantInformation
    v-if="showDisclaimer"
    class="w-full"
  >
    <p>
      The live vehicle tracking data is derived from the
      <AppLink :href="'https://www.bus-data.dft.gov.uk/'">Bus Open Data Service (BODS).</AppLink>
      A Transport Provider’s tracking data may be interrupted for a number of reasons. If a live feed goes down,
      scheduled information can be accessed from the Discover Buses and Plan a Journey functionality from within our
      homepage.
    </p>
  </ImportantInformation>
</template>
