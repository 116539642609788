class Storage {
  constructor(storageType = 'localStorage') {
    if (storageType !== 'localStorage' || storageType !== 'sessionStorage') {
      storageType = 'localStorage'
    }

    this.storageType = storageType
  }

  getItem(key) {
    try {
      const value = window[this.storageType].getItem(key) ?? ''
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  setItem(key, value) {
    const strValue = JSON.stringify(value)
    window[this.storageType].setItem(key, strValue)
  }

  removeItem(key) {
    window[this.storageType].removeItem(key)
  }
}

export default Storage
