<script setup>
import { ref } from 'vue'
import AppSpinner from '@/Shared/Components/AppSpinner.vue'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import PoiCard from './PoiCard.vue'

import { onMounted } from 'vue'

const props = defineProps({
  busRoute: Object,
})

const loading = ref(true)
const pois = ref([])
const poisAvailable = ref(false)

onMounted(() => {
  fetchPois()
})

const fetchPois = () => {
  loading.value = true

  gtfsApi
    .getPoisForRoute(props.busRoute.route_id)
    .then((response) => {
      pois.value = response.data

      if (pois.value.length > 0) {
        poisAvailable.value = true
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      loading.value = false
    })
}
</script>

<template>
  <div>
    <p class="text-sm">Poi's near this route :</p>
    <div v-if="!poisAvailable">
      <div class="flex items-center justify-center min-h-[200px]">
        <span>No POI’s near this route.</span>
      </div>
    </div>
    <div v-else>
      <div
        v-if="loading"
        class="col-span-2 flex justify-center items-center h-96"
      >
        <AppSpinner />
      </div>
      <div>
        <div
          v-if="pois.length > 0"
          class=""
        >
          <div
            v-for="poi in pois"
            :key="poi.id"
            class=""
          >
            <PoiCard :poi="poi" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
