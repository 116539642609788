<script setup>
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

const props = defineProps({
  links: {
    type: Object,
    default: null,
  },
  meta: {
    type: Object,
    default: null,
  },
})
</script>

<template>
  <nav class="flex items-center justify-between px-4 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <AppLink
        v-show="props.links.prev"
        :href="props.links.prev ? props.links.prev : 'null'"
        class="inline-flex items-center"
      >
        <ArrowLeftIcon
          class="mr-3 h-5 w-5"
          aria-hidden="true"
        />
        Previous
      </AppLink>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <div
        v-for="link in meta.links.slice(1, -1)"
        :key="link.url"
      >
        <span
          v-if="!link.url"
          class="relative hidden underline items-center px-2 py-2 text-sm font-semibold hover:ring-1 focus:z-20 focus:outline-offset-0 md:inline-flex"
        >
          ...
        </span>
        <Link
          v-else
          :only="props.loadOnly ? props.loadOnly : []"
          :href="link.url ? link.url : 'null'"
          :class="[link.active ? 'bg-black text-white' : 'text-black']"
          class="relative hidden underline items-center px-3 py-2 text-sm font-semibold hover:ring-1 focus:z-20 focus:outline-offset-0 md:inline-flex"
        >
          {{ link.label }}
        </Link>
      </div>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <AppLink
        v-show="props.links.next"
        :href="props.links.next ? props.links.next : 'null'"
        class="inline-flex items-center"
      >
        Next
        <ArrowRightIcon
          class="ml-3 h-5 w-5"
          aria-hidden="true"
        />
      </AppLink>
    </div>
  </nav>
</template>
