<script setup>
import { ref } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import BusStopIcon from '@svgs/bus-stop-icon.svg'
import RouteIcon from '@svgs/route-icon.svg'
import SignalIcon from '@svgs/signal-icon.svg'
import SelectInput from '@/Shared/Components/Forms/SelectInput.vue'

const tabs = [
  { id: 0, name: 'Discover buses', icon: BusStopIcon },
  { id: 1, name: 'Plan a journey', icon: RouteIcon },
  { id: 2, name: 'Live bus map', icon: SignalIcon },
]

const selectedTab = ref(0)

function changeTab(index) {
  selectedTab.value = index
}
</script>

<template>
  <div class="mt-8">
    <div class="lg:hidden">
      <label
        for="tabs"
        class="sr-only"
      >
        Select a tab
      </label>
      <SelectInput
        id="tabs"
        v-model="selectedTab"
        class="mb-4"
        value-key="id"
        :items="tabs"
      >
        <template #selected="{ selected }">
          <span class="ml-3 flex items-center truncate">
            <component
              :is="selected.icon"
              class="w-7 h-7 mr-2"
              aria-hidden="true"
            />
            {{ selected ? selected.name : '' }}
          </span>
        </template>
        <template #default="{ item, selected }">
          <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
            {{ item.name }}
          </span>
        </template>
      </SelectInput>
    </div>
    <div class="">
      <TabGroup
        :selected-index="selectedTab"
        @change="changeTab"
      >
        <TabList class="flex space-x-4 hidden lg:block">
          <Tab
            v-for="(tab, index) in tabs"
            :key="index"
            class="focus:outline-none focus:ring-2 focus:ring-yellow-500"
          >
            <div
              class="flex items-center border border-gray-100 border-b-0 p-4 rounded-t-lg ui-selected:bg-black ui-selected:text-white ui-not-selected:bg-white ui-not-selected:text-black"
            >
              <component
                :is="tab.icon"
                class="w-7 h-7 mr-2"
                aria-hidden="true"
              />
              <span class="text-xl font-bold">{{ tab.name }}</span>
            </div>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel tabindex="-1">
            <slot name="discover-bus-tab-panel" />
          </TabPanel>
          <TabPanel tabindex="-1">
            <slot name="plan-journey-tab-panel" />
          </TabPanel>
          <TabPanel tabindex="-1">
            <slot name="live-bus-map-tab-panel" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>
