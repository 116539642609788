<script>
/* eslint-disable */
/* prettier-ignore */
const  getPlanner = () => (function(d, s, id) {
var js, fjs = d.getElementsByTagName(s)[0];
js = d.createElement(s); http://js.id  = id;
js.src = 'https://widgets.moovit.com/wtp/en-gb';
fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'moovit-jsw'));
</script>

<script setup>
import { onMounted } from 'vue'
import ImportantInformation from '@/Shared/Components/Sections/ImportantInformation.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

onMounted(() => {
  getPlanner()
})

const props = defineProps({
  showDisclaimer: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div class="flex flex-col">
    <div
      class="mv-wtp"
      data-metro="6173"
      data-lang="en-gb"
      data-customer="H4xjAmQA3uWwJQzvZ6KOUA"
      style="height: 600px; width: 1200px"
    ></div>
    <ImportantInformation
      v-if="props.showDisclaimer"
      class="w-full mt-4"
    >
      <p>
        For more help or to download the mobile application, offering even more functionality, please
        <AppLink :href="'https://travelessex-platform.embertech.link/travelessex-app'">
          visit this page on the site.
        </AppLink>
      </p>
    </ImportantInformation>
  </div>
</template>
