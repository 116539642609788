import { defineStore } from 'pinia'
import Storage from '@/Shared/Helpers/storageHelper.js'

export const favouritesStorage = new Storage()

export let useFavouritesStore = defineStore('favourites', {
  state: () => ({
    busStops: favouritesStorage.getItem('busStops') || [],
    services: favouritesStorage.getItem('services') || [],
    parkAndRides: favouritesStorage.getItem('parkAndRides') || [],
  }),
  actions: {
    addFavourite(favouriteItem, type, url = null) {
      if (type == 'stop') {
        favouriteItem.type = 'stop'
        this.busStops.push(favouriteItem)
        favouritesStorage.setItem('busStops', this.busStops)
      } else if (type == 'service') {
        favouriteItem.type = 'service'
        this.services.push(favouriteItem)
        favouritesStorage.setItem('services', this.services)
      } else if (type == 'park-and-ride') {
        favouriteItem.type = 'park-and-ride'
        favouriteItem.url = url
        this.parkAndRides.push(favouriteItem)
        favouritesStorage.setItem('parkAndRides', this.parkAndRides)
      }
    },
    removeFavourite(favouriteItem, type) {
      if (type == 'stop') {
        this.busStops.splice(this.getBusStopIndexById(favouriteItem.stop_id), 1)
        favouritesStorage.setItem('busStops', this.busStops)
      } else if (type == 'service') {
        this.services.splice(this.getServiceIndexById(favouriteItem.route_id), 1)
        favouritesStorage.setItem('services', this.services)
      } else if (type == 'park-and-ride') {
        this.parkAndRides.splice(this.getParkAndRideIndexById(favouriteItem.route_id), 1)
        favouritesStorage.setItem('parkAndRides', this.parkAndRides)
      }
    },
    clearFavourites() {
      favouritesStorage.removeItem('busStops')
      favouritesStorage.removeItem('services')
      favouritesStorage.removeItem('parkAndRides')
      console.log(favouritesStorage)
    },
    isFavourited(favouriteItem, type) {
      if (type == 'stop') {
        return this.busStops.find((busStop) => busStop.stop_id === favouriteItem.stop_id) ? true : false
      } else if (type == 'service') {
        return this.services.find((service) => service.route_id === favouriteItem.route_id) ? true : false
      } else if (type == 'park-and-ride') {
        return this.parkAndRides.find((parkAndRide) => parkAndRide.route_id === favouriteItem.route_id) ? true : false
      }
    },
    getBusStopIndexById(id) {
      return this.busStops.findIndex((busStop) => busStop.stop_id === id)
    },
    getServiceIndexById(id) {
      return this.services.findIndex((service) => service.route_id === id)
    },
    getParkAndRideIndexById(id) {
      return this.parkAndRides.findIndex((parkAndRide) => parkAndRide.route_id === id)
    },
  },
})
