<script setup>
import { ref, onMounted } from 'vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import AutoComplete from '@/Public/Partials/Timetable/AutoComplete.vue'

const props = defineProps({
  isHomepage: Boolean,
  canSearchOnMount: Boolean,
  query: String,
})

const form = ref({ processing: false, errors: {} })

const params = new URLSearchParams(document.location.search)

const autocomplete = ref(null)

if (params.has('type') && params.has('id')) {
  const values = {
    type: params.get('type'),
    id: params.get('id'),
    label: params.get('label'),
  }

  if (params.get('type') == 'place') {
    values.lat = params.get('lat')
    values.lng = params.get('lng')
  }

  autocomplete.value = values
}

onMounted(() => {
  if (autocomplete.value && props.canSearchOnMount.value) {
    onSearch()
  }
})

const onSearch = () => {
  if (autocomplete.value == null) {
    console.log(autocomplete)
  } else {
    window.location.href = `/search-for-a-timetable/timetable/${autocomplete.value.id}`
  }
}
</script>

<template>
  <div
    class="flex max-lg:flex-col bg-white shadow-[0_0px_35px_rgba(0,0,0,0.1)] rounded-tr-lg rounded-b-lg p-4 outline-none max-lg:rounded-tl-lg"
  >
    <div
      v-if="!isHomepage"
      class="flex items-center flex-none mr-6"
    >
      <h3 class="font-semibold text-[22px] py-4">Search timetables</h3>
    </div>
    <div class="flex items-center grow max-lg:mb-4 lg:mr-4">
      <div class="relative w-full">
        <AutoComplete
          v-model="autocomplete"
          :query="props.query"
        />
      </div>
    </div>
    <div class="flex items-center flex-none">
      <PrimaryButton
        class="w-full px-12 justify-center"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing || !autocomplete"
        @click="onSearch"
      >
        Show me
      </PrimaryButton>
    </div>
  </div>
  <!--<div class="flex flex-col items-center mt-14">
    <BusLoadingIcon aria-hidden="true" />
    <h2 class="text-black font-extrabold text-3xl pt-8">Your bus options</h2>
    <h3 class="font-semibold text-[22px] py-2">We're finding your bus options</h3>
  </div>-->
</template>
