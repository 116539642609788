<script setup>
import { computed } from 'vue'
import { HeartIcon } from '@heroicons/vue/24/outline'
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import { useFavouritesStore } from '@/Public/Stores/FavouritesStore'

let newFavouritesStore = useFavouritesStore()

const props = defineProps({
  itemToFavourite: Object,
  favouriteType: String,
  disableFavouriteLink: {
    type: Boolean,
    default: false,
  },
})

const isFavourited = computed(() => {
  return newFavouritesStore.isFavourited(props.itemToFavourite, props.favouriteType)
})

const onAddToFavourites = () => {
  if (props.disableFavouriteLink) {
    return
  }
  if (props.favouriteType === 'park-and-ride') {
    const url = window.location.pathname
    newFavouritesStore.addFavourite(props.itemToFavourite, props.favouriteType, url)
  } else {
    newFavouritesStore.addFavourite(props.itemToFavourite, props.favouriteType)
  }
}
</script>

<template>
  <div class="flex items-center bg-[#ECF1F5] py-4 px-6 rounded-xl">
    <span v-if="isFavourited">This {{ favouriteType }} has been added to your favourites</span>
    <span v-else>Want to get to this page faster next time?</span>

    <div class="flex ml-auto">
      <AppLink
        v-if="isFavourited"
        href="/my-favourites"
        class="font-medium !text-black items-center inline-flex"
      >
        <div>
          <div class="p-2 mr-4 rounded-full bg-black">
            <HeartIcon
              class="h-6 w-6 transition duration-150 ease-in-out text-white"
              aria-hidden="true"
            />
          </div>
        </div>
        View favourites
      </AppLink>

      <LinkButton
        v-else
        type="button"
        class="font-medium !text-black"
        @click="onAddToFavourites"
      >
        <div>
          <div class="p-2 mr-4 rounded-full bg-[#E0CBD1]">
            <HeartIcon
              class="h-6 w-6 transition duration-150 ease-in-out text-black"
              aria-hidden="true"
            />
          </div>
        </div>
        Add to favourites
      </LinkButton>
    </div>
  </div>
</template>
