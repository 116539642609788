import '../Shared/bootstrap'

import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'
import dayjsCustomParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import dayjsDurationPlugin from 'dayjs/plugin/duration'

import { createApp } from 'vue'
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m'
import { createPinia } from 'pinia'

import AppLogo from '@/Shared/Components/AppLogo.vue'
import AppHeader from '@/Shared/Layouts/AppHeader.vue'
import BetaBanner from '@/Shared/Components/BetaBanner.vue'
import ShowMoreList from '@/Shared/Components/ShowMoreList.vue'
import ErrorLayout from '@/Public/Layouts/ErrorLayout.vue'
import PublicLeftNavigation from '@/Public/Layouts/PublicLeftNavigation.vue'
import PublicRightNavigation from '@/Public/Layouts/PublicRightNavigation.vue'
import HomeCarousel from '@/Public/Partials/Home/Carousel.vue'
import HomeAccordion from '@/Public/Partials/Home/Accordion.vue'
import BusSearch from '@/Public/Partials/ExploreBusOption/BusSearch.vue'
import TimetableSearch from '@/Public/Partials/Timetable/TimetableSearch.vue'
import TimetableSearchServiceTable from '@/Public/Partials/Timetable/TimetableSearchServiceTable.vue'
import BusLocationMap from '@/Public/Partials/BusLocation/BusLocationMap.vue'
import BusRouteMap from '@/Public/Partials/BusRoute/BusRouteMap.vue'
import BusRouteInformation from '@/Public/Partials/BusRoute/BusRouteInformation.vue'
import BusRouteTimetable from '@/Public/Partials/BusRoute/BusRouteTimetable.vue'
import MyFavourites from '@/Public/Partials/MyFavourites/MyFavourites.vue'
import SearchPanel from '@/Public/Components/SearchPanel.vue'
import HelpUsImprove from '@/Public/Components/HelpUsImprove.vue'
import Planner from '@/Public/Partials/Home/Planner.vue'
import BusStopDetails from '@/Public/Partials/ParkAndRide/BusStopDetails.vue'
import LiveBusMap from '@/Public/Partials/Home/LiveBusMap.vue'

dayjs.extend(dayjsCustomParseFormatPlugin).extend(dayjsDurationPlugin)

const app = createApp({
  components: {
    AppLogo,
    PublicLeftNavigation,
    PublicRightNavigation,
    AppHeader,
    ErrorLayout,
    ShowMoreList,
    HomeCarousel,
    HomeAccordion,
    BetaBanner,
    BusSearch,
    BusLocationMap,
    BusRouteMap,
    BusRouteInformation,
    BusRouteTimetable,
    MyFavourites,
    SearchPanel,
    HelpUsImprove,
    Planner,
    TimetableSearch,
    TimetableSearchServiceTable,
    BusStopDetails,
    LiveBusMap,
  },
})
  .use(ZiggyVue)
  .use(createPinia())
  .use(dayjs)

app.config.globalProperties.$date = dayjs
app.config.globalProperties.$pluralize = (amount, singular, plural) => (amount > 1 || amount === 0 ? plural : singular)

if (import.meta.env.VITE_APP_ENV != 'local' && import.meta.env.VITE_APP_ENV != 'testing') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_APP_ENV,
  })
}

app.mount('#public-app')
