<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import SecondaryButton from '@/Shared/Components/Buttons/SecondaryButton.vue'
import TextAreaInput from '@/Shared/Components/Forms/TextAreaInput.vue'
import InputLabel from '@/Shared/Components/Forms/InputLabel.vue'
import InputError from '@/Shared/Components/Forms/InputError.vue'
import AppSpinner from '@/Shared/Components/AppSpinner.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const loading = ref(false)
const showSuccess = ref(false)
const showImproveForm = ref(false)

const form = useForm({
  details: '',
})

const submitHelpForm = (isHelpful) => {
  let urlParams = new URLSearchParams(window.location.search)
  let searchQuery = null
  let url = window.location.pathname
  let details = null
  let searchParams = getAllUrlParams(window.location.href)

  if (urlParams.get('q') || urlParams.get('search')) {
    searchQuery = urlParams.get('q') ? urlParams.get('q') : urlParams.get('search')
  }

  if (!isHelpful) {
    details = form.details
  }

  loading.value = true

  axios
    .post(route('feedback.store'), {
      url: url,
      search_query: searchQuery,
      is_helpful: isHelpful,
      search_params: searchParams,
      details: details,
    })
    .then(() => {
      showSuccess.value = true

      if (!isHelpful) {
        clearImproveForm()
      }
    })
    .catch((error) => {
      if (!isHelpful) {
        form.setError(
          'details',
          error.response && error.response.status == 422
            ? error.response.data.errors.details[0]
            : 'An unexpected error occurred, please try again later.'
        )
      }
    })
    .finally(() => {
      loading.value = false
    })
}

const clearImproveForm = () => {
  showImproveForm.value = false
  form.clearErrors()
  form.details = ''
}

const getAllUrlParams = (url) => {
  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  var obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    var arr = queryString.split('&')

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=')

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0]
      var paramValue = typeof a[1] === 'undefined' ? true : a[1]

      // (optional) keep case consistent
      paramName = paramName.toLowerCase()
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = paramValue
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue)
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue)
        }
      }
    }
  }

  return obj
}
</script>

<template>
  <div
    v-if="!showImproveForm"
    class="w-full bg-gray-100"
  >
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="flex items-center justify-between min-h-[58px]">
        <p v-if="showSuccess">Thank you for your feedback</p>
        <p v-else>Did you find what you were looking for?</p>

        <AppSpinner
          v-if="loading"
          class="w-8 h-8"
        />

        <div v-if="!loading && !showSuccess">
          <SecondaryButton
            class="px-10 justify-center ml-4 flex-none items-center cursor-pointer rounded-md font-bold whitespace-nowrap motion-safe:transition border bg-gray-100 border-black text-black hover:bg-black hover:text-white focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:bg-gray-100 disabled:text-black disabled:opacity-25"
            @click="submitHelpForm(true)"
          >
            Yes
          </SecondaryButton>
          <SecondaryButton
            class="px-10 justify-center ml-4 flex-none items-center cursor-pointer rounded-md font-bold whitespace-nowrap motion-safe:transition border bg-gray-100 border-black text-black hover:bg-black hover:text-white focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:bg-gray-100 disabled:text-black disabled:opacity-25"
            @click="showImproveForm = true"
          >
            No
          </SecondaryButton>
        </div>

        <div
          v-if="!loading && showSuccess"
          @click="showSuccess = false"
        >
          <p
            class="flex block cursor-pointer text-black underline decoration-1 underline-offset-2 hover:decoration-2 focus:outline-none focus:bg-yellow-500 focus:decoration-2"
          >
            Close
            <XMarkIcon
              class="h-6 w-6 text-black"
              aria-hidden="true"
            />
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showImproveForm"
    class="mx-auto max-w-7xl sm:px-6 lg:px-8"
  >
    <hr class="border-t" />
    <h2 class="font-extrabold text-2xl pt-5 pb-3">Help us improve</h2>
    <p class="mb-6">We'd like your help to improve this service. Please detail below the problem you had below.</p>
    <InputLabel
      for="details"
      value="Enter your problem"
    />
    <InputError
      class="mt-2"
      :message="form.errors.details"
    />
    <TextAreaInput
      id="details"
      v-model="form.details"
      class="mt-1 block w-2/5"
      rows="7"
      placeholder=""
    />

    <div class="w-2/5">
      <div class="flex justify-start">
        <div class="mt-5">
          <AppSpinner
            v-if="loading"
            class="w-8 h-8"
          />
          <div v-if="!loading">
            <SecondaryButton
              class="px-12 justify-center flex-none items-center cursor-pointer rounded-md font-bold whitespace-nowrap motion-safe:transition border bg-white border-black text-black hover:bg-black hover:text-white focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:bg-white disabled:text-black disabled:opacity-25"
              @click="clearImproveForm"
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              class="px-12 justify-center ml-4 flex-none items-center cursor-pointer rounded-md font-bold whitespace-nowrap motion-safe:transition border bg-gray-100 border-black text-black hover:bg-black hover:text-white focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:bg-gray-100 disabled:text-black disabled:opacity-25"
              @click="submitHelpForm(false)"
            >
              Send
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
