<script setup>
import { ref, computed } from 'vue'
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import { HandRaisedIcon } from '@heroicons/vue/24/outline'
import LivePulseIcon from '@svgs/live-pulse-icon.svg'
import MapStreetPinIcon from '@svgs/map-street-pin-icon.svg'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import AppSpinner from '@/Shared/Components/AppSpinner.vue'
import { getFormattedTimeDifference } from '@/Shared/Helpers/dateHelper.js'
import { useFavouritesStore } from '@/Public/Stores/FavouritesStore'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import ConfirmationModal from '@/Shared/Components/Modals/ConfirmationModal.vue'
import SecondaryButton from '@/Shared/Components/Buttons/SecondaryButton.vue'
import DangerButton from '@/Shared/Components/Buttons/DangerButton.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

let newFavouritesStore = useFavouritesStore()

const props = defineProps({
  busStop: Object,
})

const loading = ref(true)
const upcomingBusRoutes = ref(null)
const staticStreetViewSource = ref(null)
const showConfirmationModal = ref(false)

const getUpcomingBusStopRoutes = () => {
  gtfsApi.stopDetails(props.busStop.stop_id).then((response) => {
    loading.value = false
    upcomingBusRoutes.value = response.data.routes.slice(0, 3)
  })
}

const getStaticStreetViewSource = () => {
  staticStreetViewSource.value = `https://maps.googleapis.com/maps/api/streetview?size=600x340&location=${
    props.busStop.lat
  },%20${props.busStop.lng}&fov=80&heading=70&pitch=0&source=outdoor&key=${import.meta.env.VITE_GOOGLE_API_KEY}`
}

const nextArrivalFromNow = (busRoute) => {
  const allArrivals = busRoute.next_departures_for_stop
  const nextArrival = allArrivals.length > 0 ? allArrivals[0] : null

  if (nextArrival) {
    const formattedTime = getFormattedTimeDifference(nextArrival)

    return formattedTime.length > 0 ? formattedTime : 'Due now'
  }

  return 'N/A'
}

const removeFromFavourites = () => {
  newFavouritesStore.removeFavourite(props.busStop, props.busStop.type)
}

const busStopLink = computed(() => {
  if (props.busStop.type === 'park-and-ride') {
    return props.busStop.url
  } else {
    return `/stop-details/${props.busStop.stop_id}`
  }
})

const confirmRemoveFromFavourites = () => {
  showConfirmationModal.value = true
}

const closeModal = () => {
  showConfirmationModal.value = false
}

getUpcomingBusStopRoutes()
getStaticStreetViewSource()
</script>

<template>
  <div class="bg-white rounded-xl border border-[#EEEEEE] shadow-[0_0_10px_rgba(0,0,0,0.1)]">
    <div class="relative">
      <div class="absolute top-4 right-4">
        <a
          :href="`https://www.google.com/maps/@${busStop.lat},${busStop.lng},15z?layer=c&cbll=${busStop.lat},${busStop.lng}&cbp=${busStop.heading},0,0,0`"
          target="_blank"
          class="flex items-center bg-white pl-2 pr-1 py-1 rounded-xl hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:text-black disabled:opacity-25 transition"
        >
          <MapStreetPinIcon
            class="h-8 w-8 -ml-1"
            aria-hidden="true"
          />
        </a>
      </div>
      <img
        :src="staticStreetViewSource"
        class="rounded-t-xl"
      />
    </div>

    <div class="px-4 pb-4">
      <h2 class="font-bold text-lg mt-4">{{ busStop.name }}</h2>

      <div class="flex flex-col">
        <div
          v-if="busStop.has_real_time_sign"
          class="mt-4"
        >
          <div class="flex items-center">
            <LivePulseIcon
              class="h-8 w-8 -ml-1 mr-2"
              aria-hidden="true"
            />
            <span>This stop has a real-time bus information board</span>
          </div>
        </div>
        <div
          v-if="busStop.naptan_busstop_type == 'HAR'"
          class="mt-4"
        >
          <div class="flex items-center">
            <HandRaisedIcon
              class="h-8 w-8 -ml-1 mr-2"
              aria-hidden="true"
            />
            <span>You can hail services from this stop</span>
          </div>
        </div>
      </div>

      <AppSpinner
        v-if="loading"
        class="w-8 h-8 mt-4 mx-auto"
      />

      <div
        v-else-if="!loading && upcomingBusRoutes"
        class="mt-4"
      >
        <h3 class="font-bold mb-4">Upcoming Buses</h3>

        <div class="flex justify-between">
          <p>Service</p>
          <p>Arriving</p>
        </div>

        <ul
          role="list"
          class="divide-y divide-gray-100"
        >
          <li
            v-for="busRoute in upcomingBusRoutes"
            :key="busRoute.route_id"
            class="flex justify-between gap-x-6 py-3"
          >
            <div class="flex gap-x-4">
              <span class="bg-[#1D594C] text-white py-1 px-6 rounded-lg mr-2">{{ busRoute.route_short_name }}</span>
            </div>

            <div class="hidden sm:flex sm:items-center">
              <p class="text-lg leading-6 text-[#1D594C] font-bold">{{ nextArrivalFromNow(busRoute) }}</p>
            </div>
          </li>
        </ul>
      </div>

      <PrimaryButton
        as="a"
        class="w-full py-3 justify-center"
        :href="busStopLink"
      >
        {{ props.busStop.type === 'park-and-ride' ? 'View Park and Ride' : 'View stop' }}
      </PrimaryButton>
      <div class="mt-4">
        <AppLink
          href=" https://www.travelessex.co.uk/advice/how-to-give-feedback"
          class="ml-auto font-medium"
        >
          Report an issue with this stop
        </AppLink>
        <LinkButton
          class="font-medium my-2"
          @click="confirmRemoveFromFavourites"
        >
          Remove from favourites
        </LinkButton>
      </div>
    </div>
  </div>
  <ConfirmationModal :show="showConfirmationModal">
    <template #title>
      <p class="text-xl">
        Removing
        <span class="font-bold">{{ props.busStop.name }}</span>
        from Favourites
      </p>
    </template>
    <template #content><p class="text-xl">Are You sure ?</p></template>

    <template #footer>
      <SecondaryButton
        class="mr-4"
        @click="closeModal"
      >
        No
      </SecondaryButton>
      <DangerButton
        class="mr-4"
        @click="removeFromFavourites"
      >
        Yes
      </DangerButton>
    </template>
  </ConfirmationModal>
</template>
