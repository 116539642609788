<script setup>
import { ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import BusStopMarkerIcon from '@svgs/bus-stop-marker-icon.svg'
import LivePulseIcon from '@svgs/live-pulse-icon.svg'
import MapStreetPinIcon from '@svgs/map-street-pin-icon.svg'
import WalkIcon from '@svgs/walk-icon.svg'
import { GoogleMap, Circle, Marker, CustomMarker } from 'vue3-google-map'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import AddToFavourites from '@/Public/Components/AddToFavourites.vue'
import AppSpinner from '@/Shared/Components/AppSpinner.vue'
import BusRouteCard from '@/Public/Partials/BusLocation/BusRouteCard.vue'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import { HandRaisedIcon } from '@heroicons/vue/24/outline'
import { onMounted } from 'vue'
import SecondaryButton from '@/Shared/Components/Buttons/SecondaryButton.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import PoiIcon from '@/Shared/Components/PoiIcon.vue'

//props icon is used here to check if the view is a poi or no as the icon variable is only passed when the view is a poi
const props = defineProps({
  lat: Number,
  lng: Number,
  stops: Array,
  selected: {
    type: Object,
    default: null,
  },
  icon: null,
})

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['geometry', 'places'],
})

const center = { lat: props.lat, lng: props.lng }
const walkingDistance = ref(null)
const staticStreetViewSource = ref(null)

let distanceMatrixService = null

const apiPromise = loader.load()

apiPromise.then((google) => {
  distanceMatrixService = new google.maps.DistanceMatrixService()
})

const getWalkingDistanceToStop = () => {
  distanceMatrixService.getDistanceMatrix(
    {
      origins: [`${props.lat}, ${props.lng}`],
      destinations: [`${selectedStop.value.lat}, ${selectedStop.value.lng}`],
      travelMode: 'WALKING',
    },
    (response, status) => {
      if (status === 'OK') {
        walkingDistance.value = humanizeDuration(response.rows[0].elements[0].duration.value)
      }
    }
  )
}

const getStaticStreetViewSource = () => {
  staticStreetViewSource.value = `https://maps.googleapis.com/maps/api/streetview?size=600x340&location=${
    selectedStop.value.lat
  },%20${selectedStop.value.lng}&fov=80&heading=70&pitch=0&source=outdoor&key=${import.meta.env.VITE_GOOGLE_API_KEY}`
}

const humanizeDuration = (durationInSeconds) => {
  const minutes = Math.round(durationInSeconds / 60)
  if (minutes === 0) {
    return 'Less than 1 min'
  } else if (minutes === 1) {
    return '1 min'
  } else {
    return `${minutes} mins`
  }
}

const circleOptions = {
  clickable: false,
  center: center,
  radius: 500,
  strokeColor: '#E97135',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillOpacity: 0,
}

const selectedStop = ref(null)
const selectedStopRoutes = ref(null)
const loading = ref(false)
const cardLimit = ref(3)
const showMore = ref(false)

onMounted(() => {
  if (props.selected) {
    onStopSelected(props.selected)
  }
})

const onStopSelected = (stop) => {
  selectedStop.value = stop

  //all changes here are happening as selected stop format != resourceStop format
  if (props.selected && stop.stop_lon) {
    selectedStop.value.lng = stop.stop_lon
    selectedStop.value.name = stop.stop_name
    selectedStop.value.lat = stop.stop_lat
  }

  selectedStopRoutes.value = null

  loading.value = true

  gtfsApi.stopDetails(stop.stop_id).then((response) => {
    loading.value = false
    selectedStopRoutes.value = response.data.routes
  })

  //if props is selected that means that selected stop = start position therefore the route wont be needed
  if (!props.selected) {
    getWalkingDistanceToStop()
  }

  getStaticStreetViewSource()

  const element = document.getElementById('selectedStop')

  element.scrollIntoView({
    behavior: 'smooth', // Use smooth scrolling animation
    block: 'start', // Scroll to the top of the element
  })
}

const increaseCardLimit = () => {
  if (cardLimit.value === 3) {
    cardLimit.value = -1
    showMore.value = true
  } else {
    cardLimit.value = 3
    showMore.value = false
  }
}
</script>

<template>
  <GoogleMap
    :api-promise="apiPromise"
    class="w-full h-[500px] my-4"
    :center="center"
    :zoom="15"
    :styles="[
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ]"
  >
    <div v-if="!props.icon">
      <Marker :options="{ position: center }" />
    </div>
    <div v-else>
      <CustomMarker :options="{ position: center, zIndex: 10 }">
        <div
          class="w-5 h-5 sm:w-10 sm:h-10 flex-shrink-0 rounded-full flex justify-center items-center self-start bg-gray-400"
        >
          <PoiIcon :poi-type="props.icon" />
        </div>
      </CustomMarker>
    </div>
    <CustomMarker
      v-for="stop in stops"
      :key="stop.stop_id"
      :options="{ position: { lat: parseFloat(stop.lat), lng: parseFloat(stop.lng) } }"
    >
      <BusStopMarkerIcon
        class="cursor-pointer"
        @click="onStopSelected(stop)"
      />
    </CustomMarker>

    <Circle :options="circleOptions" />
  </GoogleMap>
  <div id="selectedStop">
    <div v-if="selectedStop">
      <p class="text-lg font-bold">You've selected the following stop:</p>
      <div class="flex items-center my-6">
        <BusStopMarkerIcon class="mr-3" />
        <div>
          <h5 class="text-black font-extrabold text-[22px]">{{ selectedStop.name }}</h5>
          <p class="text-xl">{{ selectedStop.indicator }}</p>
        </div>
        <AppLink
          href=" https://www.travelessex.co.uk/advice/how-to-give-feedback"
          class="ml-auto text-sm font-medium"
        >
          Report an issue with this stop
        </AppLink>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="relative">
          <div class="absolute top-4 right-4">
            <a
              :href="`https://www.google.com/maps/@${selectedStop.lat},${selectedStop.lng},15z?layer=c&cbll=${selectedStop.lat},${selectedStop.lng}&cbp=${selectedStop.heading},0,0,0`"
              target="_blank"
              class="flex items-center bg-white px-4 py-2 rounded-xl hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:text-black disabled:opacity-25 transition"
            >
              <MapStreetPinIcon
                class="h-8 w-8 -ml-1 mr-2"
                aria-hidden="true"
              />
              <span class="font-bold">See street view</span>
            </a>
          </div>
          <img
            :src="staticStreetViewSource"
            class="rounded-xl"
          />
        </div>
        <div class="flex flex-col gap-y-6">
          <div v-if="walkingDistance">
            <div class="flex items-center">
              <WalkIcon
                class="h-8 w-8 -ml-1 mr-2"
                aria-hidden="true"
              />
              <span class="font-bold">{{ walkingDistance }} walk to your location</span>
            </div>
            <AppLink
              :href="`https://www.google.com/maps/dir/?api=1&origin=${props.lat},${props.lng}&destination=${selectedStop.lat},${selectedStop.lng}&travelmode=walking`"
              class="ml-auto text-sm font-medium"
            >
              Get directions to this bus stop
            </AppLink>
          </div>
          <div v-if="selectedStop.has_real_time_sign">
            <div class="flex items-center">
              <LivePulseIcon
                class="h-8 w-8 -ml-1 mr-2"
                aria-hidden="true"
              />
              <span class="font-bold">This stop has a real-time bus information board</span>
            </div>
            <AppLink
              href="#"
              class="ml-auto text-sm font-medium"
            >
              Find out how to use real-time bus information boards
            </AppLink>
          </div>
          <div v-if="selectedStop.naptan_busstop_type == 'HAR'">
            <div class="flex items-center">
              <HandRaisedIcon
                class="h-8 w-8 -ml-1 mr-2"
                aria-hidden="true"
              />
              <span class="font-bold">You can hail services from this stop</span>
            </div>
            <AppLink
              href="#"
              class="ml-auto text-sm font-medium"
            >
              Find out more about hailing a bus
            </AppLink>
          </div>
          <AddToFavourites
            class="mt-auto"
            favourite-type="stop"
            :item-to-favourite="selectedStop"
          />
        </div>
      </div>
      <AppSpinner
        v-if="loading"
        class="w-8 h-8 mt-10 mx-auto"
      />
      <div
        v-else-if="!loading && selectedStopRoutes"
        class="mt-8"
      >
        <p class="text-lg font-bold">
          This stop has {{ selectedStopRoutes.length }}
          {{ $pluralize(selectedStopRoutes.length, 'service', 'services') }}
        </p>
        <!--
        <ImportantInformation
          v-if="!selectedStopRoutes.length"
          class="mt-5 mb-1"
        >
          <p class="p-2 text-center">
            Looking to find services for a particular date/time -
            <AppLink
              :href="'/about-bus-services/plan-a-journey'"
              intertia="true"
            >
              try our journey planner instead.
            </AppLink>
          </p>
        </ImportantInformation> -->

        <div class="mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">
          <BusRouteCard
            v-for="route in cardLimit === 3 ? selectedStopRoutes.slice(0, cardLimit) : selectedStopRoutes"
            :key="route.route_id"
            :bus-route="route"
          />
        </div>
      </div>
    </div>

    <div
      v-if="selectedStopRoutes && Object.keys(selectedStopRoutes).length > 3"
      class="p-8 text-center"
    >
      <SecondaryButton
        class="border border-2 border-black"
        @click="increaseCardLimit()"
      >
        <ChevronDownIcon
          :class="[showMore ? 'rotate-180' : '', 'h-6 w-6 text-secondary transition ease-in-out']"
          class="mr-2 h-6 w-6"
          aria-hidden="true"
        />
        {{ showMore ? 'Show Less' : 'Show More' }}
      </SecondaryButton>
    </div>
  </div>
</template>
