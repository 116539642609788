<script setup>
import { HeartIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue'

const isMyFavouritesRoute = computed(() => {
  return route().current('my-favourites.index')
})
</script>

<template>
  <div
    class="rounded-full py-1.5 px-2.5"
    :class="isMyFavouritesRoute ? 'bg-black' : 'bg-white'"
  >
    <a
      href="/my-favourites"
      class="block focus:outline-none focus:ring-2 focus:ring-yellow-500"
      :class="isMyFavouritesRoute ? 'text-white' : 'text-black'"
    >
      <div class="flex items-center">
        <HeartIcon
          class="h-6 w-6 transition duration-150 ease-in-out mr-2"
          aria-hidden="true"
        />
        <span class="sr-only">Open my favourites</span>
        <span class="max-lg:hidden">My Favourites</span>
      </div>
    </a>
  </div>
</template>
