<script setup>
import axios from 'axios'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import debounce from 'lodash/debounce'
import { Loader } from '@googlemaps/js-api-loader'
import { ref, computed, watch } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import InputLabel from '@/Shared/Components/Forms/InputLabel.vue'
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue'
// import { XCircleIcon } from '@heroicons/vue/20/solid'
import { useBreakpoint } from '@/Shared/Composables/useBreakpoint.js'
import { useModelWrapper } from '@/Shared/Composables/useModelWrapper.js'
import BusRouteIcon from '@svgs/bus-route-icon.svg'
import { MapPinIcon } from '@heroicons/vue/24/outline'
import PoiIcon from '@/Shared/Components/PoiIcon.vue'

const props = defineProps({
  modelValue: Object,
})

const loading = ref(false)
const noResults = ref(false)
const search = ref(null)

const emits = defineEmits(['update:modelValue'])
const { currentBreakpoint } = useBreakpoint()
const selectedOption = useModelWrapper(props, emits)

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['geometry', 'places'],
})

let placesService = null
let autocompleteService = null

loader
  .load()
  .then((google) => {
    placesService = new google.maps.places.PlacesService(document.createElement('div'))
    autocompleteService = new google.maps.places.AutocompleteService()
  })
  .catch((e) => console.error(e))

const placeOptions = ref([])
const gtfsOptions = ref([])
const poiOptions = ref([])

const options = computed(() => {
  return [...placeOptions.value, ...gtfsOptions.value, ...poiOptions.value].sort((b, a) => a.sort.localeCompare(b.sort))
})

const intelliOptions = computed(() => {
  const boldSearchInput = `<b>${search.value}</b>`
  return options.value.map((option) => {
    const label = option.label.toLowerCase()
    const index = label.indexOf(search.value.toLowerCase())
    if (index > -1) {
      const boldLabel = `${label.slice(0, index)}${boldSearchInput}${label.slice(index + search.value.length)}`
      return { ...option, intelliLabel: boldLabel }
    }

    return { ...option, intelliLabel: option.label }
  })
})

watch(selectedOption, () => {
  if (selectedOption.value && selectedOption.value.type === 'place') {
    placesService.getDetails({ placeId: selectedOption.value.id, fields: ['geometry'] }, (place, status) => {
      if (status === 'OK') {
        const { lat, lng } = place.geometry.location
        selectedOption.value.lat = lat()
        selectedOption.value.lng = lng()
      }
    })
  }
})

watch(
  search,
  debounce(() => {
    if (search.value.length === 0) {
      noResults.value = false
      placeOptions.value = []
      gtfsOptions.value = []
      poiOptions.value = []
      return
    }

    loading.value = true

    axios
      .all([
        autocompleteService.getPlacePredictions({
          input: search.value,
          componentRestrictions: { country: ['uk'] },
          types: [],
          locationRestriction: {
            north: 52.094316,
            south: 51.419518,
            east: 1.972765,
            west: -0.019,
          },
          // Priortise from the center of Essex
          origin: { lat: 51.811707, lng: 0.538784 },
        }),

        gtfsApi.autocomplete(search.value),
        gtfsApi.autocompletePois(search.value),
      ])
      .then(
        axios.spread((placesResponse, gtfsResponse, poiResponse) => {
          if (placesResponse && placesResponse.predictions) {
            placeOptions.value = placesResponse.predictions.map((place) => {
              return {
                type: 'place',
                id: place.place_id,
                label: place.description,
                sort: place.description,
              }
            })
          }
          if (gtfsResponse.status === 200) {
            gtfsOptions.value = gtfsResponse.data.map((route) => {
              return {
                type: 'route',
                id: route.route_id,
                label: `${route.name} - ${route.headsigns.join(', ')}, ${route.agency_name}`,
                sort: `${route.headsigns.join(', ')}, ${route.agency_name}`,
              }
            })
          }
          if (poiResponse.status === 200) {
            poiOptions.value = poiResponse.data.map((poi) => {
              return {
                type: 'poi',
                id: `${poi.id}`,
                label: `${poi.name}`,
                sort: `${poi.name}`,
                lat: `${poi.coordinates.coordinates[1]}`,
                lng: `${poi.coordinates.coordinates[0]}`,
                icon: `${poi.icon}`,
              }
            })
          }

          noResults.value =
            placeOptions.value.length === 0 && gtfsOptions.value.length === 0 && poiOptions.value.length === 0
        })
      )
      .catch((e) => console.error(e))
      .finally(() => (loading.value = false))
  }, 500)
)

const onReset = () => {
  selectedOption.value = null
  window.history.replaceState(null, null, window.location.href.split('?')[0])
}
</script>

<template>
  <div>
    <Combobox
      v-model="selectedOption"
      nullable
    >
      <div class="relative">
        <div
          class="relative w-full cursor-default rounded-lg bg-white text-left sm:text-sm"
          @click="onReset"
        >
          <InputLabel
            for="search"
            class="sr-only"
            value="Enter a location, postcode or bus number to find nearby bus stops and routes"
          />
          <ComboboxInput
            autocomplete="off"
            class="w-full border-black rounded-md pl-10 shadow-sm focus:border-2 focus:ring focus:ring-yellow-500"
            :display-value="(option) => (option ? option.label : '')"
            :placeholder="
              currentBreakpoint == 'xs' || currentBreakpoint == 'sm'
                ? 'Enter a location, postcode or bus no.'
                : 'Enter a location, postcode or bus number to find nearby bus stops and routes'
            "
            @change="search = $event.target.value"
          />

          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <!-- <button
            v-if="selectedOption !== null"
            type="button"
            class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none focus:ring focus:ring-yellow-500"
            tabindex="0"
            @click="onReset"
          >
            <XCircleIcon
              class="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </button> -->
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="search = ''"
        >
          <ComboboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          >
            <div
              v-if="loading && options.length === 0"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              Loading...
            </div>
            <div
              v-else-if="noResults"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              No results found...
            </div>

            <ComboboxOption
              v-for="(option, index) in intelliOptions"
              :key="index"
              v-slot="{ active }"
              as="template"
              :value="option"
            >
              <li
                class="relative cursor-pointer select-none py-2 px-4"
                :class="{
                  'bg-yellow-500 text-black': active,
                  'text-black': !active,
                }"
              >
                <div class="flex">
                  <BusRouteIcon
                    v-if="option.type == 'route'"
                    class="text-gray-400 mr-3 mt-4"
                    aria-hidden="true"
                  />
                  <PoiIcon
                    v-else-if="option.type == 'poi'"
                    :poi-type="option.icon"
                    class="text-gray-400 ml-1 mr-3 mt-3"
                    aria-hidden="true"
                  />
                  <MapPinIcon
                    v-else
                    class="w-6 h-6 mr-3 mt-2"
                  />
                  <div>
                    <span
                      class="block truncate"
                      v-html="option.intelliLabel"
                    ></span>
                    <span class="block text-sm">
                      {{ option.type === 'route' ? 'Bus route' : option.type === 'poi' ? 'POI' : 'Location' }}
                    </span>
                  </div>
                </div>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>
