<script setup>
import { ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import BusStopMarkerIcon from '@svgs/bus-stop-marker-icon.svg'
import LivePulseIcon from '@svgs/live-pulse-icon.svg'
import MapStreetPinIcon from '@svgs/map-street-pin-icon.svg'
import WalkIcon from '@svgs/walk-icon.svg'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import AddToFavourites from '@/Public/Components/AddToFavourites.vue'
import { onMounted } from 'vue'

const props = defineProps({
  stop: {
    type: Object,
    default: null,
  },
  stopText: String,
})

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['geometry', 'places'],
})

const walkingDistance = ref(null)
const apiPromise = loader.load()
const staticStreetViewSource = ref(null)
const userPosition = ref(null)
let distanceMatrixService = null

const humanizeDuration = (durationInSeconds) => {
  const minutes = Math.round(durationInSeconds / 60)
  if (minutes === 0) {
    return 'Less than 1 min'
  } else if (minutes === 1) {
    return '1 min'
  } else {
    return `${minutes} mins`
  }
}

apiPromise.then((google) => {
  distanceMatrixService = new google.maps.DistanceMatrixService()
  getWalkingDistanceToStop()
})

const getWalkingDistanceToStop = () => {
  if (userPosition.value) {
    distanceMatrixService.getDistanceMatrix(
      {
        origins: [{ lat: userPosition.value.lat, lng: userPosition.value.lng }],
        destinations: [`${props.stop.lat}, ${props.stop.lng}`],
        travelMode: 'WALKING',
      },
      (response, status) => {
        if (status === 'OK') {
          walkingDistance.value = humanizeDuration(response.rows[0].elements[0].duration.value)
        }
      }
    )
  }
}

const getStaticStreetViewSource = () => {
  staticStreetViewSource.value = `https://maps.googleapis.com/maps/api/streetview?size=600x340&location=${
    props.stop.lat
  },%20${props.stop.lng}&fov=80&heading=70&pitch=0&source=outdoor&key=${import.meta.env.VITE_GOOGLE_API_KEY}`
}

onMounted(() => {
  navigator.geolocation.getCurrentPosition((position) => {
    if (position.coords.latitude && position.coords.longitude) {
      userPosition.value = { lat: position.coords.latitude, lng: position.coords.longitude }
    }
  })
  getStaticStreetViewSource()
})
</script>

<template>
  <div>
    <div class="flex items-center my-6">
      <BusStopMarkerIcon class="mr-3" />
      <h5 class="text-black font-extrabold text-[22px]">{{ stop.name }}</h5>

      <AppLink
        href="https://www.travelessex.co.uk/advice/how-to-give-feedback"
        class="ml-auto text-sm font-medium"
      >
        Report an issue with this stop
      </AppLink>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div class="relative">
        <div class="absolute top-4 right-4">
          <a
            :href="`https://www.google.com/maps/@${stop.lat},${stop.lng},15z?layer=c&cbll=${stop.lat},${stop.lng}&cbp=${stop.heading},0,0,0`"
            target="_blank"
            class="flex items-center bg-white px-4 py-2 rounded-xl hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:text-black disabled:opacity-25 transition"
          >
            <MapStreetPinIcon
              class="h-8 w-8 -ml-1 mr-2"
              aria-hidden="true"
            />
            <span class="font-bold">See street view</span>
          </a>
        </div>
        <img
          :src="staticStreetViewSource"
          class="rounded-xl"
        />
      </div>
      <div class="flex flex-col gap-y-6">
        <div v-if="walkingDistance">
          <div class="flex items-center">
            <WalkIcon
              class="h-8 w-8 -ml-1 mr-2"
              aria-hidden="true"
            />
            <span class="font-bold">{{ walkingDistance }} walk to your location</span>
          </div>
          <AppLink
            :href="`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${stop.lat},${stop.lng}&travelmode=walking`"
            class="ml-auto text-sm font-medium"
          >
            Get directions to this bus stop
          </AppLink>
        </div>
        <div v-if="stop.has_real_time_sign">
          <div class="flex items-center">
            <LivePulseIcon
              class="h-8 w-8 -ml-1 mr-2"
              aria-hidden="true"
            />
            <span class="font-bold">This stop has a real-time bus information board</span>
          </div>
          <AppLink
            href="#"
            class="ml-auto text-sm font-medium"
          >
            Find out how to use real-time bus information boards
          </AppLink>
        </div>
        <div
          v-if="stopText"
          class="mt-auto"
        >
          <p>
            {{ stopText }}
          </p>
        </div>
        <AddToFavourites
          class="mt-auto"
          favourite-type="park-and-ride"
          :item-to-favourite="stop"
        />
      </div>
    </div>
  </div>
</template>
