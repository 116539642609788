<script setup>
import { computed, ref } from 'vue'
import LinkButton from '@/Shared/Components/Buttons/LinkButton.vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'

import ConfirmationModal from '@/Shared/Components/Modals/ConfirmationModal.vue'
import SecondaryButton from '@/Shared/Components/Buttons/SecondaryButton.vue'
import DangerButton from '@/Shared/Components/Buttons/DangerButton.vue'
import { useFavouritesStore } from '@/Public/Stores/FavouritesStore'

let newFavouritesStore = useFavouritesStore()

const props = defineProps({
  service: Object,
})
const showConfirmationModal = ref(false)

const serviceLink = computed(() => {
  return `/about-bus-services/explore-bus-options/route/${props.service.route_id}`
})

const removeFromFavourites = () => {
  newFavouritesStore.removeFavourite(props.service, 'service')
}
const confirmRemoveFromFavourites = () => {
  showConfirmationModal.value = true
}

const closeModal = () => {
  showConfirmationModal.value = false
}
</script>

<template>
  <div class="bg-white rounded-xl border border-[#EEEEEE] divide-y divide-[#DEDEDE] shadow-[0_0_10px_rgba(0,0,0,0.1)]">
    <div class="px-4 pt-4 pb-4">
      <div class="flex items-center">
        <span class="bg-[#1D594C] text-white py-1 px-6 rounded-lg mr-2">{{ service.route_short_name }}</span>
        <h4 class="font-semibold text-[#1D594C] text-lg truncate">to {{ service.route_long_name }}</h4>
      </div>
    </div>
    <div class="px-4 pb-4">
      <dl class="grid grid-cols-1">
        <div class="p-4 sm:col-span-1 sm:px-0">
          <dt class="text-sm">First bus</dt>
          <dd class="mt-1 sm:col-span-2 sm:mt-0">
            {{ service.first_departure.outbound ?? 'N/A' }} (Out)
            <span class="px-2">|</span>
            {{ service.first_departure.inbound ?? 'N/A' }} (In)
          </dd>
        </div>
        <div class="p-1 mb-2 sm:col-span-1 sm:px-0">
          <dt class="text-sm">Last bus</dt>
          <dd class="mt-1 sm:col-span-2 sm:mt-0">
            {{ service.last_departure.outbound ?? 'N/A' }} (Out)
            <span class="px-2">|</span>
            {{ service.last_departure.inbound ?? 'N/A' }} (In)
          </dd>
        </div>
      </dl>

      <PrimaryButton
        as="a"
        class="w-full py-3 justify-center mb-4 mt-1"
        :href="serviceLink"
      >
        View bus route
      </PrimaryButton>

      <LinkButton class="font-medium my-1">Find out about fare prices</LinkButton>
      <LinkButton
        class="font-medium my-2"
        @click="confirmRemoveFromFavourites"
      >
        Remove from favourites
      </LinkButton>
    </div>
  </div>
  <ConfirmationModal :show="showConfirmationModal">
    <template #title>
      <p class="text-xl">
        Removing
        <span class="font-bold">{{ props.service.route_short_name }}</span>
        route from Favourites
      </p>
    </template>
    <template #content><p class="text-xl">Are You sure ?</p></template>

    <template #footer>
      <SecondaryButton
        class="mr-4"
        @click="closeModal"
      >
        No
      </SecondaryButton>
      <DangerButton @click="removeFromFavourites">Yes</DangerButton>
    </template>
  </ConfirmationModal>
</template>
