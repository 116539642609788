<script setup>
import { CurrencyPoundIcon, CreditCardIcon, DevicePhoneMobileIcon, WifiIcon } from '@heroicons/vue/24/outline'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import AddToFavourites from '@/Public/Components/AddToFavourites.vue'

const props = defineProps({
  busRoute: Object,
})

const serviceKeys = ['accepts_card', 'accepts_cash', 'has_operator_app', 'has_wifi']

const services = [
  { key: 'accepts_card', title: 'Card payment accepted', icon: CreditCardIcon, link: '#' },
  { key: 'accepts_cash', title: 'Cash accepted', icon: CurrencyPoundIcon, link: '#' },
  { key: 'has_operator_app', title: 'Provider has an app available', icon: DevicePhoneMobileIcon, link: '#' },
  { key: 'has_wifi', title: 'This service has WiFi', icon: WifiIcon, link: '#' },
]

const availableServices = []

if (props.busRoute.agency_profile) {
  serviceKeys.forEach((key) => {
    if (props.busRoute.agency_profile[key]) {
      availableServices.push(services.find((service) => service.key == key))
    }
  })
}
</script>

<template>
  <div>
    <h2 class="px-2 md:px-0 text-lg font-bold pb-4">Service information</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <dl>
          <div class="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center">
            <dt class="text-sm">Route</dt>
            <dd class="mt-1 sm:col-span-2 sm:mt-0 font-bold text-[#1D594C]">{{ busRoute.route_long_name }}</dd>
          </div>
          <!--<div class="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center">
            <dt class="text-sm">Arriving nearby in</dt>
            <dd class="mt-1 sm:col-span-2 sm:mt-0">{{ busRoute.next_departures_in_minutes }}</dd>
          </div>-->
          <div class="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center">
            <dt class="text-sm">Provider</dt>
            <dd class="mt-1 sm:col-span-2 sm:mt-0">
              <AppLink
                v-if="busRoute.agency_profile"
                :href="`http://${busRoute.agency_profile.website}`"
                target="_blank"
                class="font-medium inline-block"
                :label="busRoute.agency_profile.name"
                external
              />
              <span v-else>{{ busRoute.agency_name }}</span>
            </dd>
          </div>
          <div
            v-if="busRoute.first_departure.outbound || busRoute.first_departure.inbound"
            class="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center"
          >
            <dt class="text-sm">First bus</dt>
            <dd class="mt-1 sm:col-span-2 sm:mt-0">
              {{ busRoute.first_departure.outbound ?? 'N/A' }} (Out)
              <span class="px-2">|</span>
              {{ busRoute.first_departure.inbound ?? 'N/A' }} (In)
            </dd>
          </div>
          <div
            v-if="busRoute.last_departure.outbound || busRoute.last_departure.inbound"
            class="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center"
          >
            <dt class="text-sm">Last bus</dt>
            <dd class="mt-1 sm:col-span-2 sm:mt-0">
              {{ busRoute.last_departure.outbound ?? 'N/A' }} (Out)
              <span class="px-2">|</span>
              {{ busRoute.last_departure.inbound ?? 'N/A' }} (In)
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <div v-if="availableServices.length > 0">
          <p class="bg-[#ECF1F5] rounded-xl p-4 mb-4">
            The information displayed here differs between providers and therefore may not be 100％ accurate.
          </p>

          <div class="divide-y divide-[#DEDEDE]">
            <div
              v-for="availableService in availableServices"
              :key="availableService.key"
              class="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center"
            >
              <div class="flex items-center sm:col-span-2">
                <component
                  :is="availableService.icon"
                  class="h-8 w-8 -ml-1 mr-2"
                  aria-hidden="true"
                />
                <span>{{ availableService.title }}</span>
              </div>
              <AppLink
                :href="availableService.link"
                class="font-medium ml-auto"
              >
                Find out more
              </AppLink>
            </div>
          </div>
        </div>

        <AddToFavourites
          class="mt-4"
          favourite-type="service"
          :item-to-favourite="busRoute"
        />
      </div>
    </div>
  </div>
</template>
