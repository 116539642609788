<script setup>
import FoodIcon from '@svgs/statamic_icons/poi/food-icon.svg'
import MedicalIcon from '@svgs/statamic_icons/poi/medical-icon.svg'
import MuseumIcon from '@svgs/statamic_icons/poi/museum-icon.svg'
import ParkAndRideIcon from '@svgs/statamic_icons/poi/park-ride-icon.svg'
import ShoppingIcon from '@svgs/statamic_icons/poi/shopping-icon.svg'
import SportIcon from '@svgs/statamic_icons/poi/sport-icon.svg'
import TrainIcon from '@svgs/statamic_icons/poi/train-icon.svg'
import TreeIcon from '@svgs/statamic_icons/poi/trees-icon.svg'

const props = defineProps({
  poiType: String,
})

const poiIcons = {
  'food-icon': FoodIcon,
  'medical-icon': MedicalIcon,
  'museum-icon': MuseumIcon,
  'park-ride-icon': ParkAndRideIcon,
  'sport-icon': SportIcon,
  'train-icon': TrainIcon,
  'trees-icon': TreeIcon,
  'shopping-icon': ShoppingIcon,
}
</script>

<template>
  <component :is="poiIcons[props.poiType]" />
</template>
