import axios from 'axios'

const instance = axios.create({
  baseURL: '/api/general-transport',
  timeout: 15000,
})

export default {
  autocomplete(search, limit = 15) {
    return instance({
      url: `/autocomplete?search=${search}&limit=${limit}`,
      method: 'get',
    })
  },

  autocompleteTimetables(search, limit = 20) {
    return instance({
      url: `/autocomplete-timetable?search=${search}&limit=${limit}`,
      method: 'get',
    })
  },

  autocompletePois(search, limit = 20) {
    return instance({
      url: `/autocomplete-pois?search=${search}&limit=${limit}`,
      method: 'get',
    })
  },

  routeTimetable(route, date) {
    return instance({
      url: `/timetable/route/${route}?date=${date}`,
      method: 'get',
    })
  },

  stopDetails(stop) {
    return instance({
      url: `/stop/${stop}`,
      method: 'get',
    })
  },

  stopLiveUpdates(stop) {
    return instance({
      url: `/stop/${stop}/live-updates`,
      method: 'get',
    })
  },

  stopsNearest(lat, lng, radius = 0.5) {
    return instance({
      url: `/nearest?lat=${lat}&lng=${lng}&radius=${radius}`,
      method: 'get',
    })
  },

  liveUpdatesNearest(lat, lng, radius = 0.5, routeShortName = '') {
    return instance({
      url: `/live-updates/nearest?lat=${lat}&lng=${lng}&radius=${radius}&route_short_name=${routeShortName}`,
      method: 'get',
    })
  },

  drtServices() {
    return instance({
      url: `/drt-services`,
      method: 'get',
    })
  },

  getPoisForRoute(route_id) {
    return instance({
      url: `/${route_id}/pois`,
      method: 'get',
    })
  },
}
