<script setup>
import { ref, watchEffect } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'
import emblaCarouselAutoplay from 'embla-carousel-autoplay'

const props = defineProps({
  loop: {
    type: Boolean,
    default: true,
  },
  speed: {
    type: Number,
    default: 7,
  },
  progress: {
    type: Boolean,
    default: true,
  },
  progressPosition: {
    type: String,
    default: 'absolute bottom-8 left-10',
  },
})

const [emblaNode, emblaApi] = emblaCarouselVue({ loop: props.speed, speed: props.speed }, [
  emblaCarouselAutoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
])

const dots = ref([])
const currentIndex = ref(1)

watchEffect(() => {
  if (emblaApi.value) {
    dots.value = emblaApi.value.scrollSnapList()

    emblaApi.value.on('select', () => {
      currentIndex.value = emblaApi.value.selectedScrollSnap() + 1
    })
  }
})
</script>

<template>
  <div
    ref="emblaNode"
    class="overflow-hidden relative"
  >
    <div class="flex flex-row h-auto relative">
      <slot />
    </div>
    <div
      v-if="progress"
      :class="props.progressPosition"
    >
      <span>{{ currentIndex }} of {{ dots.length }}</span>
      <div class="relative w-[92px] h-2.5 rounded-full overflow-hidden">
        <div class="w-full h-full bg-white absolute"></div>
        <div
          class="transition-all ease-out duration-300 h-full bg-blue-500 relative w-0"
          :style="{ width: `${(currentIndex / dots.length) * 100}%` }"
        />
      </div>
    </div>
  </div>
</template>
